"use client";
import {
  createProjectApiV1ProjectsPost,
  deleteProjectApiV1ProjectsProjectIdDelete,
  getProjectApiV1ProjectsProjectIdGet,
  listProjectsApiV1ProjectsGet,
} from "@llamaindex/cloud/api";
import {
  QueryClient,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import { startTransition } from "react";

export const PROJECT_KEY = "projectInfo";
export const PROJECTS_KEY = "projects";

export function useProjectById({ projectId }: { projectId: string }) {
  return useSuspenseQuery({
    queryKey: [PROJECT_KEY, projectId],
    queryFn: async () => {
      const { data } = await getProjectApiV1ProjectsProjectIdGet({
        path: {
          project_id: projectId,
        },
        throwOnError: true,
      });
      return data;
    },
  });
}

export async function refreshProjectById(
  queryClient: QueryClient,
  projectId: string,
) {
  await queryClient.invalidateQueries({
    queryKey: [PROJECT_KEY, projectId],
  });
}

export async function refreshProjects(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [PROJECTS_KEY],
  });
}

export function useProjects(params?: { organizationId?: string }) {
  return useSuspenseQuery({
    queryKey: [PROJECTS_KEY, params],
    queryFn: async () => {
      const { data } = await listProjectsApiV1ProjectsGet({
        query: {
          organization_id: params?.organizationId,
        },
        throwOnError: true,
      });
      return data;
    },
  });
}

export function useNewProject() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      name,
      organizationId,
    }: {
      name: string;
      organizationId?: string;
    }) => {
      const { data } = await createProjectApiV1ProjectsPost({
        body: {
          name,
        },
        query: {
          organization_id: organizationId,
        },
        throwOnError: true,
      });
      return data;
    },
    onSuccess: async () => {
      await refreshProjects(queryClient);
    },
  });
}

export function useDeleteProject() {
  const queryClient = useQueryClient();
  const { data: projects } = useProjects();
  const { replace } = useRouter();
  return useMutation({
    mutationFn: async ({ projectId }: { projectId: string }) => {
      if (projects.length === 1) {
        throw new Error("Cannot delete the last project");
      }
      const { data } = await deleteProjectApiV1ProjectsProjectIdDelete({
        path: {
          project_id: projectId,
        },
        throwOnError: true,
      });
      return data;
    },
    onSuccess: async (_, { projectId }) => {
      await refreshProjects(queryClient);
      const targetProjectId = projects.find(
        (project) => project.id !== projectId,
      )?.id;
      if (targetProjectId) {
        startTransition(() => {
          replace(`/project/${targetProjectId}/pipeline`);
        });
      }
    },
  });
}
