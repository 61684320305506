import { ParserLanguages } from "@llamaindex/cloud/api";
import { Option } from "@llamaindex/component/ui/multiple-selector";

export const LLAMA_PARSE_EXAMPLE_PARSING_INSTRUCTION = `The provided document is a manga comic book. Most pages do NOT have a title.
It does not contain tables.
Try to reconstruct the dialogue spoken in a cohesive way.`;

export const LLAMA_PARSE_LANGUAGE_OPTIONS: Option<ParserLanguages>[] = [
  { label: "af", value: "af" },
  { label: "az", value: "az" },
  { label: "bs", value: "bs" },
  { label: "cs", value: "cs" },
  { label: "cy", value: "cy" },
  { label: "da", value: "da" },
  { label: "de", value: "de" },
  { label: "en", value: "en" },
  { label: "es", value: "es" },
  { label: "et", value: "et" },
  { label: "fr", value: "fr" },
  { label: "ga", value: "ga" },
  { label: "hr", value: "hr" },
  { label: "hu", value: "hu" },
  { label: "id", value: "id" },
  { label: "is_", value: "is" },
  { label: "it", value: "it" },
  { label: "ku", value: "ku" },
  { label: "la", value: "la" },
  { label: "lt", value: "lt" },
  { label: "lv", value: "lv" },
  { label: "mi", value: "mi" },
  { label: "ms", value: "ms" },
  { label: "mt", value: "mt" },
  { label: "nl", value: "nl" },
  { label: "no", value: "no" },
  { label: "oc", value: "oc" },
  { label: "pi", value: "pi" },
  { label: "pl", value: "pl" },
  { label: "pt", value: "pt" },
  { label: "ro", value: "ro" },
  { label: "rs_latin", value: "rs_latin" },
  { label: "sk", value: "sk" },
  { label: "sl", value: "sl" },
  { label: "sq", value: "sq" },
  { label: "sv", value: "sv" },
  { label: "sw", value: "sw" },
  { label: "tl", value: "tl" },
  { label: "tr", value: "tr" },
  { label: "uz", value: "uz" },
  { label: "vi", value: "vi" },
  { label: "ar", value: "ar" },
  { label: "fa", value: "fa" },
  { label: "ug", value: "ug" },
  { label: "ur", value: "ur" },
  { label: "bn", value: "bn" },
  { label: "as_", value: "as" },
  { label: "mni", value: "mni" },
  { label: "ru", value: "ru" },
  { label: "rs_cyrillic", value: "rs_cyrillic" },
  { label: "be", value: "be" },
  { label: "bg", value: "bg" },
  { label: "uk", value: "uk" },
  { label: "mn", value: "mn" },
  { label: "abq", value: "abq" },
  { label: "ady", value: "ady" },
  { label: "kbd", value: "kbd" },
  { label: "ava", value: "ava" },
  { label: "dar", value: "dar" },
  { label: "inh", value: "inh" },
  { label: "che", value: "che" },
  { label: "lbe", value: "lbe" },
  { label: "lez", value: "lez" },
  { label: "tab", value: "tab" },
  { label: "tjk", value: "tjk" },
  { label: "hi", value: "hi" },
  { label: "mr", value: "mr" },
  { label: "ne", value: "ne" },
  { label: "bh", value: "bh" },
  { label: "mai", value: "mai" },
  { label: "ang", value: "ang" },
  { label: "bho", value: "bho" },
  { label: "mah", value: "mah" },
  { label: "sck", value: "sck" },
  { label: "new", value: "new" },
  { label: "gom", value: "gom" },
  { label: "sa", value: "sa" },
  { label: "bgc", value: "bgc" },
  { label: "th", value: "th" },
  { label: "ch_sim", value: "ch_sim" },
  { label: "ch_tra", value: "ch_tra" },
  { label: "ja", value: "ja" },
  { label: "ko", value: "ko" },
  { label: "ta", value: "ta" },
  { label: "te", value: "te" },
  { label: "kn", value: "kn" },
];

export const ERROR_TOAST_DURATION_MS = 15000;

export const VALID_SYNC_INTERVALS = [
  { label: "None", value: `${0}` },
  { label: "Every 6 hours", value: `${6 * 60 * 60}` },
  { label: "Every 12 hours", value: `${12 * 60 * 60}` },
  { label: "Every 24 hours", value: `${24 * 60 * 60}` },
];

export const DEFAULT_ORG_NAME = "Default Org";
